<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row class="match-height">
        <b-col lg="12">
          <b-overlay
            :show="ShowRound"
            variant="transparent"
            opacity="0.99"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <div class="text-center">
                <b-icon-controller
                  font-scale="3"
                  animation="cylon"
                />
                <p id="cancel-label">
                  กรุณารอสักครู่...
                </p>
              </div>
            </template>
            <b-card>
              <b-row>
                <b-col lg="6">
                  <h3>อัตราการจ่ายเงิน</h3>
                </b-col>

              </b-row>

              <b-form-group
                label="หวย"
                label-for="customDelimiter"
              >
                <v-select
                  v-model="LottoHead"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="ListSelect"
                  :reduce="ele => ele.value"
                  @input="LottoSubHead = null ,GetRate()"
                />

              </b-form-group>
              <b-form-checkbox
                v-model="AutoRate"
                class="custom-control-success"
                @input="GetRate()"
              >
                อัตราจ่ายของบริษัท
              </b-form-checkbox>
              <b-form-group
                v-if="LottoSubList"
                label="ประเภทหวย"
                label-for="customDelimiter"
              >
                <v-select
                  v-model="LottoSubHead"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="LottoSubList"
                  :reduce="ele => ele.value"
                  @input="GetRate()"
                />
              </b-form-group>

              <b-row v-if="(LottoHead && !LottoSubList) || LottoSubHead">
                <!-- <b-row> -->
                <b-col
                  v-if="LottoStatus(LottoHead,LottoSubHead,'u3')"
                  cols="12"
                  class="mt-1 mb-1"
                >
                  <hr>
                  <b-row>
                    <b-col cols="12">
                      <h4>หวย 3 ตัวบน</h4>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="รับแทงสูงสุดต่อเลข3 ตัวบน"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required|integer"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="BetLimitU3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินรับแทงสูงสุดต่อเลข3 ตัวบน"
                            @input="PayLimitU3 = BetLimitU3*U3"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่าย 3 ตัวบน"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required|integer"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="U3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินที่จ่าย 3 ตัวบน"
                            @input="PayLimitU3 = BetLimitU3*U3"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่ายสูงสุด 3 ตัวบน"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required|integer"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="PayLimitU3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินจ่ายสูงสุด 3 ตัวบน"
                            @input="BetLimitU3 = (PayLimitU3/U3).toFixed()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงขั้นต่ำต่อครั้ง/เลข 3 ตัวบน"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MinU3"
                            :disabled="AutoRate"
                            type="number"
                            placeholder="กรอกจำนวนแทงขั้นต่ำต่อครั้ง/เลข 3 ตัวบน"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงสูงสุดต่อครั้ง 3 ตัวบน"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MaxU3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนแทงสูงสุดต่อครั้ง 3 ตัวบน"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  v-if="LottoStatus(LottoHead,LottoSubHead,'d3')"
                  cols="12"
                  class="mt-1 mb-1"
                >
                  <hr>
                  <b-row>
                    <b-col cols="12">
                      <h4>หวย 3 ตัวหน้า</h4>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="รับแทงสูงสุดต่อเลข 3 ตัวหน้า"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required|integer"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="BetLimitD3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินรับแทงสูงสุดต่อเลข 3 ตัวหน้า"
                            @input="PayLimitD3 = BetLimitD3*D3"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่าย 3 ตัวหน้า"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required|integer"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="D3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินที่จ่าย 3 ตัวหน้า"
                            @input="PayLimitD3 = BetLimitD3*D3"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่ายสูงสุด 3 ตัวหน้า"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required|integer"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="PayLimitD3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินจ่ายสูงสุด 3 ตัวหน้า"
                            @input="BetLimitD3 = (PayLimitD3/D3).toFixed()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงขั้นต่ำต่อครั้ง/เลข 3 ตัวล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MinD3"
                            :disabled="AutoRate"
                            type="number"
                            placeholder="กรอกจำนวนแทงขั้นต่ำต่อครั้ง/เลข 3 ตัวล่าง"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงสูงสุดต่อครั้ง 3 ตัวล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MaxD3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนแทงสูงสุดต่อครั้ง 3 ตัวล่าง"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  v-if="LottoStatus(LottoHead,LottoSubHead,'b3')"
                  cols="12"
                  class="mt-1 mb-1"
                >
                  <hr>
                  <b-row>
                    <b-col cols="12">
                      <h4>หวย 3 ตัวท้าย</h4>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="รับแทงสูงสุดต่อเลข 3 ตัวท้าย"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required|integer"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="BetLimitB3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินรับแทงสูงสุดต่อเลข3 ตัวล่าง"
                            @input="PayLimitB3 = BetLimitB3*B3"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่าย 3 ตัวล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required|integer"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="B3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินที่จ่าย 3 ตัวล่าง"
                            @input="PayLimitB3 = BetLimitB3*B3"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่ายสูงสุด 3 ตัวล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required|integer"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="PayLimitB3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินจ่ายสูงสุด 3 ตัวล่าง"
                            @input="BetLimitB3 = (PayLimitB3/B3).toFixed()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงขั้นต่ำต่อครั้ง/เลข 3 ตัวล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MinB3"
                            type="number"
                            :disabled="AutoRate"
                            placeholder="กรอกจำนวนแทงขั้นต่ำต่อครั้ง/เลข 3 ตัวล่าง"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงสูงสุดต่อครั้ง 3 ตัวล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MaxB3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนแทงสูงสุดต่อครั้ง 3 ตัวล่าง"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  v-if="LottoStatus(LottoHead,LottoSubHead,'t3')"
                  cols="12"
                  class="mt-1 mb-1"
                >
                  <hr>
                  <b-row>
                    <b-col cols="12">
                      <h4>หวย 3 ตัวโต๊ด</h4>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="รับแทงสูงสุดต่อเลข3 ตัวโต๊ด"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="BetLimitT3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินรับแทงสูงสุดต่อเลข3 ตัวโต๊ด"
                            @input="PayLimitT3 = BetLimitT3*T3"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่าย 3 ตัวโต๊ด"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="T3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินที่จ่าย 3 ตัวโต๊ด"
                            @input="PayLimitT3 = BetLimitT3*T3"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่ายสูงสุด 3 ตัวโต๊ด"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="PayLimitT3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินจ่ายสูงสุด 3 ตัวโต๊ด"
                            @input="BetLimitT3 = (PayLimitT3/T3).toFixed()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงขั้นต่ำต่อครั้ง/เลข 3 ตัวโต๊ด"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MinT3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนแทงขั้นต่ำต่อครั้ง/เลข 3 ตัวโต๊ด"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงสูงสุดต่อครั้ง 3 ตัวโต๊ด"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MaxT3"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนแทงสูงสุดต่อครั้ง 3 ตัวโต๊ด"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  v-if="LottoStatus(LottoHead,LottoSubHead,'u2')"
                  cols="12"
                  class="mt-1 mb-1"
                >
                  <hr>
                  <b-row>
                    <b-col cols="12">
                      <h4>หวย 2 ตัวบน</h4>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="รับแทงสูงสุดต่อเลข2 ตัวบน"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="BetLimitU2"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินรับแทงสูงสุดต่อเลข2 ตัวบน"
                            @input="PayLimitU2 = BetLimitU2*U2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่าย 2 ตัวบน"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="U2"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินที่จ่าย 2 ตัวบน"
                            @input="PayLimitU2 = BetLimitU2*U2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่ายสูงสุด 2 ตัวบน"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="PayLimitU2"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินจ่ายสูงสุด 2 ตัวบน"
                            @input="BetLimitU3 = (PayLimitU3/U3).toFixed()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงขั้นต่ำต่อครั้ง/เลข 2 ตัวบน"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MinU2"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนแทงขั้นต่ำต่อครั้ง/เลข 2 ตัวบน"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงสูงสุดต่อครั้ง 2 ตัวบน"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MaxU2"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนแทงสูงสุดต่อครั้ง 2 ตัวบน"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  v-if="LottoStatus(LottoHead,LottoSubHead,'d2')"
                  cols="12"
                  class="mt-1 mb-1"
                >
                  <hr>
                  <b-row>
                    <b-col cols="12">
                      <h4>หวย 2 ตัวล่าง</h4>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="รับแทงสูงสุดต่อเลข2 ตัวล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="BetLimitD2"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินรับแทงสูงสุดต่อเลข2 ตัวล่าง"
                            @input="PayLimitD2 = BetLimitD2*D2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่าย 2 ตัวล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="D2"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินที่จ่าย 2 ตัวล่าง"
                            @input="PayLimitD2 = BetLimitD2*D2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่ายสูงสุด 2 ตัวล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="PayLimitD2"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินจ่ายสูงสุด 2 ตัวล่าง"
                            @input="BetLimitD2 = (PayLimitD2/D2).toFixed()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงขั้นต่ำต่อครั้ง/เลข 2 ตัวล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MinD2"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนแทงขั้นต่ำต่อครั้ง/เลข 2 ตัวล่าง"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงสูงสุดต่อครั้ง 2 ตัวล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MaxD2"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนแทงสูงสุดต่อครั้ง 2 ตัวล่าง"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  v-if="LottoStatus(LottoHead,LottoSubHead,'t2')"
                  cols="12"
                  class="mt-1 mb-1"
                >
                  <hr>
                  <b-row>
                    <b-col cols="12">
                      <h4>หวย 2 ตัวโต๊ด(บน)</h4>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="รับแทงสูงสุดต่อเลข2 ตัวโต๊ด(บน)"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="BetLimitT2"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินรับแทงสูงสุดต่อเลข2 ตัวโต๊ด(บน)"
                            @input="PayLimitT2 = BetLimitT2*T2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่าย 2 ตัวโต๊ด(บน)"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="T2"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินที่จ่าย 2 ตัวโต๊ด(บน)"
                            @input="PayLimitT2 = BetLimitT2*T2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่ายสูงสุด 2 ตัวโต๊ด(บน)"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="PayLimitT2"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินจ่ายสูงสุด 2 ตัวโต๊ด(บน)"
                            @input="BetLimitT2 = (PayLimitT2/T2).toFixed()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงขั้นต่ำต่อครั้ง/เลข 2 ตัวโต๊ด(บน)"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MinT2"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนแทงขั้นต่ำต่อครั้ง/เลข 2 ตัวโต๊ด(บน)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงสูงสุดต่อครั้ง 2 ตัวโต๊ด(บน)"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MaxT2"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนแทงสูงสุดต่อครั้ง 2 ตัวโต๊ด(บน)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  v-if="LottoStatus(LottoHead,LottoSubHead,'u1')"
                  cols="12"
                  class="mt-1 mb-1"
                >
                  <hr>
                  <b-row>
                    <b-col cols="12">
                      <h4>หวย วิ่งบน</h4>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="รับแทงสูงสุดต่อเลขวิ่งบน"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="BetLimitU1"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินรับแทงสูงสุดต่อเลขวิ่งบน"
                            @input="PayLimitU1 = BetLimitU1*U1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่าย วิ่งบน"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="U1"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินที่จ่าย วิ่งบน"
                            @input="PayLimitU1 = BetLimitU1*U1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่ายสูงสุด วิ่งบน"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="PayLimitU1"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินจ่ายสูงสุด วิ่งบน"
                            @input="BetLimitU1 = (PayLimitU1/U1).toFixed()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงขั้นต่ำต่อครั้ง/เลข วิ่งบน"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MinU1"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนแทงขั้นต่ำต่อครั้ง/เลข วิ่งบน"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงสูงสุดต่อครั้ง วิ่งบน"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MaxU1"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนแทงสูงสุดต่อครั้ง วิ่งบน"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  v-if="LottoStatus(LottoHead,LottoSubHead,'d1')"
                  cols="12"
                  class="mt-1 mb-1"
                >
                  <hr>
                  <b-row>
                    <b-col cols="12">
                      <h4>หวย วิ่งล่าง</h4>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="รับแทงสูงสุดต่อเลขวิ่งล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="BetLimitD1"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินรับแทงสูงสุดต่อเลขวิ่งล่าง"
                            @input="PayLimitD1 = BetLimitD1*D1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่าย วิ่งล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="D1"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินที่จ่าย วิ่งล่าง"
                            @input="PayLimitD1 = BetLimitD1*D1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="จ่ายสูงสุด วิ่งล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="PayLimitD1"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนเงินจ่ายสูงสุด วิ่งล่าง"
                            @input="BetLimitD1 = (PayLimitD1/D1).toFixed()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงขั้นต่ำต่อครั้ง/เลข วิ่งล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="MinD1"
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MinD1"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนแทงขั้นต่ำต่อครั้ง/เลข วิ่งล่าง"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group
                        label="แทงสูงสุดต่อครั้ง วิ่งล่าง"
                        label-for="basicInput"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=""
                          rules="required"
                        >
                          <b-form-input
                            id="basicInput"
                            v-model="MaxD1"
                            type="number"
                            :disabled="AutoRate"
                            :state="errors.length > 0 ? false:null"
                            placeholder="กรอกจำนวนแทงสูงสุดต่อครั้ง วิ่งล่าง"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col lg="12">
                  <div class="d-flex justify-content-end">
                    <b-button
                      variant="primary"
                      @click="validationForm"
                    >
                      ส่งข้อมูล
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-overlay>
        </b-col>

        <!-- <b-col lg="12">
          <b-card no-body>
            <b-card-body>
              <div class="p-1">
                <h3>เรททั้งหมด</h3>
              </div>
            </b-card-body>
            <b-table
              small
              striped
              hover
              responsive
              class="position-relative items-center"
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
            >
              <template #cell(index)="data">
                {{ perPage * (currentPage - 1) + (data.index + 1) }}
              </template>
              <template #cell(LottoSubHead)="data">
                {{ data.item.LottoSubHead ? NameLottoSubHead(data.item.LottoHead,data.item.LottoSubHead) : '-' }}
              </template>
              <template #cell(MinU3)="data">
                {{ `${data.item.MinU3 ? Commas(data.item.MinU3) : '-' } / ${data.item.MaxU3 ? Commas(data.item.MaxU3) : '-' }` }}
              </template>
              <template #cell(MinD3)="data">
                {{ `${data.item.MinD3 ? Commas(data.item.MinD3) : '-' } / ${data.item.MaxD3 ? Commas(data.item.MaxD3) : '-' }` }}
              </template>
              <template #cell(MinT3)="data">
                {{ `${data.item.MinT3 ? Commas(data.item.MinT3) : '-' } / ${data.item.MaxT3 ? Commas(data.item.MaxT3) : '-' }` }}
              </template>
              <template #cell(MinU2)="data">
                {{ `${data.item.MinU2 ? Commas(data.item.MinU2) : '-' } / ${data.item.MaxU2 ? Commas(data.item.MaxU2) : '-' }` }}
              </template>
              <template #cell(MinD2)="data">
                {{ `${data.item.MinD2 ? Commas(data.item.MinD2) : '-' } / ${data.item.MaxD2 ? Commas(data.item.MaxD2) : '-' }` }}
              </template>
              <template #cell(MinT2)="data">
                {{ `${data.item.MinT2 ? Commas(data.item.MinT2) : '-' } / ${data.item.MaxT2 ? Commas(data.item.MaxT2) : '-' }` }}
              </template>
              <template #cell(MinU1)="data">
                {{ `${data.item.MinU1 ? Commas(data.item.MinU1) : '-' } / ${data.item.MaxU1 ? Commas(data.item.MaxU1) : '-' }` }}
              </template>
              <template #cell(MinD1)="data">
                {{ `${data.item.MinD1 ? Commas(data.item.MinD1) : '-' } / ${data.item.MaxD1 ? Commas(data.item.MaxD1) : '-' }` }}
              </template>

              <template #cell(BetLimitU3)="data">
                {{ `${data.item.BetLimitU3 ? Commas(data.item.BetLimitU3)+'/'+Commas(data.item.BetLimitU3*data.item.U3) : '-' }` }}
              </template>
              <template #cell(BetLimitD3)="data">
                {{ `${data.item.BetLimitD3 ? Commas(data.item.BetLimitD3)+'/'+Commas(data.item.BetLimitD3*data.item.D3) : '-' }` }}
              </template>
              <template #cell(BetLimitT3)="data">
                {{ `${data.item.BetLimitT3 ? Commas(data.item.BetLimitT3)+'/'+Commas(data.item.BetLimitT3*data.item.T3) : '-' }` }}
              </template>
              <template #cell(BetLimitU2)="data">
                {{ `${data.item.BetLimitU2 ? Commas(data.item.BetLimitU2)+'/'+Commas(data.item.BetLimitU2*data.item.U2) : '-' }` }}
              </template>
              <template #cell(BetLimitD2)="data">
                {{ `${data.item.BetLimitD2 ? Commas(data.item.BetLimitD2)+'/'+Commas(data.item.BetLimitD2*data.item.D2) : '-' }` }}
              </template>
              <template #cell(BetLimitT2)="data">
                {{ `${data.item.BetLimitT2 ? Commas(data.item.BetLimitT2)+'/'+Commas(data.item.BetLimitT2*data.item.T2) : '-' }` }}
              </template>
              <template #cell(BetLimitU1)="data">
                {{ `${data.item.BetLimitU1 ? Commas(data.item.BetLimitU1)+'/'+Commas(data.item.BetLimitU1*data.item.U1) : '-' }` }}
              </template>
              <template #cell(BetLimitD1)="data">
                {{ `${data.item.BetLimitD1 ? Commas(data.item.BetLimitD1)+'/'+Commas(data.item.BetLimitD1*data.item.D1) : '-' }` }}
              </template>
            </b-table>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1 ml-1 pb-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
        </b-card-body>
        </b-card>
        </b-col>  -->
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
  // BPagination,
  // BTable,
  // BInputGroupPrepend,
  // BFormSelect,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
// import moment from 'moment-timezone'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, integer, between, length,
} from '@validations'

export default {
  components: {
    vSelect,
    // BInputGroupPrepend,
    // BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    // BPagination,
    // BTable,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      AutoRate: false,
      between,
      integer,
      length,
      required,
      ShowRound: false,
      show: true,
      depositdata: [],
      member: '',
      amount: '',
      optionsMember: '',
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      pageOptions: [10, 15, 20],
      perPage: 5,
      /* eslint-disable global-require */
      items: [],
      ListSelect: [],
      LottoHead: null,
      LottoSubHead: null,
      LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoSubList: null,
      U3: 0,
      D3: 0,
      B3: 0,
      T3: 0,
      U2: 0,
      D2: 0,
      T2: 0,
      U1: 0,
      D1: 0,

      MinU3: 0,
      MinD3: 0,
      MinB3: 0,
      MinT3: 0,
      MinU2: 0,
      MinD2: 0,
      MinT2: 0,
      MinU1: 0,
      MinD1: 0,

      MaxU3: 0,
      MaxD3: 0,
      MaxB3: 0,
      MaxT3: 0,
      MaxU2: 0,
      MaxD2: 0,
      MaxT2: 0,
      MaxU1: 0,
      MaxD1: 0,

      BetLimitU3: 0,
      BetLimitD3: 0,
      BetLimitB3: 0,
      BetLimitT3: 0,
      BetLimitU2: 0,
      BetLimitD2: 0,
      BetLimitT2: 0,
      BetLimitU1: 0,
      BetLimitD1: 0,

      PayLimitU3: 0,
      PayLimitD3: 0,
      PayLimitB3: 0,
      PayLimitT3: 0,
      PayLimitU2: 0,
      PayLimitD2: 0,
      PayLimitT2: 0,
      PayLimitU1: 0,
      PayLimitD1: 0,
      ListLotto: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  async created() {
    this.LottoList = await this.GetSublist()
    // console.log(this.LottoList)
  },
  mounted() {

  },
  methods: {
    // eslint-disable-next-line consistent-return
    async GetSublist() {
      const List = []
      try {
        const { data: Res } = await this.$http.get('LottoList')
        this.ListLotto = Res
        // eslint-disable-next-line
        for (const x in Res) {
          let SubList = null
          if (Res[x].Subhead.length > 0) {
            SubList = []
            // eslint-disable-next-line
            for (const c in Res[x].Subhead) {
              SubList.push(
                { text: Res[x].Subhead[c].name, value: Res[x].Subhead[c].SubHeadID, Data: Res[x].Subhead[c] },
              )
            }
          }
          List.push(
            {
              text: Res[x].name,
              value: Res[x].HeadID,
              SubList,
              Data: Res[x],
            },
          )
          this.ListSelect.push({
            text: Res[x].name,
            value: Res[x].HeadID,
          })
        }
        return List
      } catch (e) {
        console.log(e)
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submit()
        }
      })
    },
    async GetRate() {
      if ((this.LottoHead && !this.LottoSubList) || this.LottoSubHead) {
        this.ShowRound = true
        try {
          this.ClearValue()
          if (!this.AutoRate) {
            const params = {
              LottoHead: this.LottoHead,
              LottoSubHead: this.LottoSubHead,
            }
            const { data: Res } = await this.$http.get('rate/show', { params })
            if (Res && Res.success) {
              const Rate = Res.mes
              this.CalRate(Rate)
              this.ShowRound = false
            } else {
              this.ClearValue()
              this.ShowRound = false
            }
          } else {
            const IndexHead = this.LottoList.findIndex(({ value }) => value === this.LottoHead)
            if (IndexHead >= 0) {
              const params = {
                TypeID: this.LottoList[IndexHead].Data.TypeID,
              }
              const { data: Res } = await this.$http.get('auto/showlottoList', { params })
              if (Res) {
                this.CalRateAuto(Res)
                this.ShowRound = false
              } else {
                this.ClearValue()
                this.ShowRound = false
              }
              console.log(Res)
            }
          }
        } catch (e) {
          console.log(e)
          this.ShowRound = false
        }
      }
    },
    CalRate(Rate) {
      this.U3 = Rate.U3
      this.D3 = Rate.D3
      this.B3 = Rate.B3
      this.T3 = Rate.T3
      this.U2 = Rate.U2
      this.D2 = Rate.D2
      this.T2 = Rate.T2
      this.U1 = Rate.U1
      this.D1 = Rate.D1

      this.MinU3 = Rate.MinU3
      this.MinD3 = Rate.MinD3
      this.MinB3 = Rate.MinB3
      this.MinT3 = Rate.MinT3
      this.MinU2 = Rate.MinU2
      this.MinD2 = Rate.MinD2
      this.MinT2 = Rate.MinT2
      this.MinU1 = Rate.MinU1
      this.MinD1 = Rate.MinD1

      this.MaxU3 = Rate.MaxU3
      this.MaxD3 = Rate.MaxD3
      this.MaxB3 = Rate.MaxB3
      this.MaxT3 = Rate.MaxT3
      this.MaxU2 = Rate.MaxU2
      this.MaxD2 = Rate.MaxD2
      this.MaxT2 = Rate.MaxT2
      this.MaxU1 = Rate.MaxU1
      this.MaxD1 = Rate.MaxD1

      this.BetLimitU3 = Rate.BetLimitU3
      this.BetLimitD3 = Rate.BetLimitD3
      this.BetLimitB3 = Rate.BetLimitB3
      this.BetLimitT3 = Rate.BetLimitT3
      this.BetLimitU2 = Rate.BetLimitU2
      this.BetLimitD2 = Rate.BetLimitD2
      this.BetLimitT2 = Rate.BetLimitT2
      this.BetLimitU1 = Rate.BetLimitU1
      this.BetLimitD1 = Rate.BetLimitD1

      this.PayLimitU3 = Rate.BetLimitU3 * Rate.U3
      this.PayLimitD3 = Rate.BetLimitD3 * Rate.D3
      this.PayLimitB3 = Rate.BetLimitB3 * Rate.B3
      this.PayLimitT3 = Rate.BetLimitT3 * Rate.T3
      this.PayLimitU2 = Rate.BetLimitU2 * Rate.U2
      this.PayLimitD2 = Rate.BetLimitD2 * Rate.D2
      this.PayLimitT2 = Rate.BetLimitT2 * Rate.T2
      this.PayLimitU1 = Rate.BetLimitU1 * Rate.U1
      this.PayLimitD1 = Rate.BetLimitD1 * Rate.D1
    },
    CalRateAuto(Rate) {
      this.U3 = Rate.rate_u3
      this.D3 = Rate.rate_d3
      this.B3 = Rate.rate_b3
      this.T3 = Rate.rate_t3
      this.U2 = Rate.rate_u2
      this.D2 = Rate.rate_d2
      this.T2 = Rate.rate_t2
      this.U1 = Rate.rate_u1
      this.D1 = Rate.rate_d1

      this.MinU3 = Rate.min_u3
      this.MinD3 = Rate.min_d3
      this.MinB3 = Rate.min_b3
      this.MinT3 = Rate.min_t3
      this.MinU2 = Rate.min_u2
      this.MinD2 = Rate.min_d2
      this.MinT2 = Rate.min_t2
      this.MinU1 = Rate.min_u1
      this.MinD1 = Rate.min_d1

      this.MaxU3 = Rate.max_u3
      this.MaxD3 = Rate.max_d3
      this.MaxB3 = Rate.max_b3
      this.MaxT3 = Rate.max_t3
      this.MaxU2 = Rate.max_u2
      this.MaxD2 = Rate.max_d2
      this.MaxT2 = Rate.max_t2
      this.MaxU1 = Rate.max_u1
      this.MaxD1 = Rate.max_d1

      this.BetLimitU3 = Rate.limit_u3
      this.BetLimitD3 = Rate.limit_d3
      this.BetLimitB3 = Rate.limit_b3
      this.BetLimitT3 = Rate.limit_t3
      this.BetLimitU2 = Rate.limit_u2
      this.BetLimitD2 = Rate.limit_d2
      this.BetLimitT2 = Rate.limit_t2
      this.BetLimitU1 = Rate.limit_u1
      this.BetLimitD1 = Rate.limit_d1

      this.PayLimitU3 = Rate.limit_u3 * Rate.rate_u3
      this.PayLimitD3 = Rate.limit_d3 * Rate.rate_d3
      this.PayLimitB3 = Rate.limit_b3 * Rate.rate_b3
      this.PayLimitT3 = Rate.limit_t3 * Rate.rate_t3
      this.PayLimitU2 = Rate.limit_u2 * Rate.rate_u2
      this.PayLimitD2 = Rate.limit_d2 * Rate.rate_d2
      this.PayLimitT2 = Rate.limit_t2 * Rate.rate_t2
      this.PayLimitU1 = Rate.limit_u1 * Rate.rate_u1
      this.PayLimitD1 = Rate.limit_d1 * Rate.rate_d1
    },
    submit() {
      // console.log(this.ListLotto)
      const LottoOn = this.ListLotto.find(({ HeadID }) => HeadID === this.LottoHead)
      // console.log(LottoOn)
      this.show = true
      const formData = {
        LottoHead: this.LottoHead,
        LottoSubHead: this.LottoSubHead,
        LottoTypeHead: LottoOn.TypeID,
        U3: this.U3,
        D3: this.D3,
        B3: this.B3,
        T3: this.T3,
        U2: this.U2,
        D2: this.D2,
        T2: this.T2,
        U1: this.U1,
        D1: this.D1,

        MinU3: this.MinU3,
        MinD3: this.MinD3,
        MinB3: this.MinB3,
        MinT3: this.MinT3,
        MinU2: this.MinU2,
        MinD2: this.MinD2,
        MinT2: this.MinT2,
        MinU1: this.MinU1,
        MinD1: this.MinD1,

        MaxU3: this.MaxU3,
        MaxD3: this.MaxD3,
        MaxB3: this.MaxB3,
        MaxT3: this.MaxT3,
        MaxU2: this.MaxU2,
        MaxD2: this.MaxD2,
        MaxT2: this.MaxT2,
        MaxU1: this.MaxU1,
        MaxD1: this.MaxD1,

        BetLimitU3: this.BetLimitU3,
        BetLimitD3: this.BetLimitD3,
        BetLimitB3: this.BetLimitB3,
        BetLimitT3: this.BetLimitT3,
        BetLimitU2: this.BetLimitU2,
        BetLimitD2: this.BetLimitD2,
        BetLimitT2: this.BetLimitT2,
        BetLimitU1: this.BetLimitU1,
        BetLimitD1: this.BetLimitD1,
      }
      // .post('rate/store', formData)
      this.$http
        .post('rate/store', formData)
        .then(() => {
          this.show = false
          this.LottoHead = null
          this.LottoSubHead = null
          this.ClearValue()
          this.GetRateAll()
          this.Success('บันทึกอัตราการจ่ายเงิน สำเร็จ')
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    LottoStatus(Head, Sub, Val) {
      if (!Head) return false
      const IndexHead = this.LottoList.findIndex(({ value }) => value === Head)
      if (IndexHead >= 0) {
        if (this.LottoList[IndexHead].SubList && Sub) {
          const IndexSub = this.LottoList[IndexHead].SubList.findIndex(({ value }) => value === Sub)
          if (IndexSub >= 0) {
            return this.LottoList[IndexHead].SubList[IndexSub].Data[Val] === 1
          }
        } else if (!this.LottoList[IndexHead].SubList) {
          return this.LottoList[IndexHead].Data[Val] === 1
        } else {
          return false
        }
      }
      return false
    },
    ClearValue() {
      this.U3 = null
      this.D3 = null
      this.B3 = null
      this.T3 = null
      this.U2 = null
      this.D2 = null
      this.T2 = null
      this.U1 = null
      this.D1 = null

      this.MinU3 = null
      this.MinD3 = null
      this.MinB3 = null
      this.MinT3 = null
      this.MinU2 = null
      this.MinD2 = null
      this.MinT2 = null
      this.MinU1 = null
      this.MinD1 = null

      this.MaxU3 = null
      this.MaxD3 = null
      this.MaxB3 = null
      this.MaxT3 = null
      this.MaxU2 = null
      this.MaxD2 = null
      this.MaxT2 = null
      this.MaxU1 = null
      this.MaxD1 = null

      this.BetLimitU3 = null
      this.BetLimitD3 = null
      this.BetLimitB3 = null
      this.BetLimitT3 = null
      this.BetLimitU2 = null
      this.BetLimitD2 = null
      this.BetLimitT2 = null
      this.BetLimitU1 = null
      this.BetLimitD1 = null

      this.PayLimitU3 = null
      this.PayLimitD3 = null
      this.PayLimitB3 = null
      this.PayLimitT3 = null
      this.PayLimitU2 = null
      this.PayLimitD2 = null
      this.PayLimitT2 = null
      this.PayLimitU1 = null
      this.PayLimitD1 = null
    },
    Commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
